export const SET_DASHBOARD_ENTITIES = 'SET_DASHBOARD_ENTITIES'
export const SET_DASHBOARD_FILTERS = 'SET_DASHBOARD_FILTERS'
export const SET_COMPARE_FILTERS = 'SET_COMPARE_FILTERS'

export const INITIAL_DASHBOARD_ENTITY_STATE = {
    all: [],
    total: 0,
    filters: { limit: 100, offset: 0 },
}

export const SET_ANALYZE_ENTITY = 'SET_ANALYZE_ENTITY'
export const SET_ANALYZE_SCHOOL_ENTITY = 'SET_ANALYZE_SCHOOL_ENTITY'
export const SET_ANALYZE_ENTITY_DATA = 'SET_ANALYZE_ENTITY_DATA'
export const SET_ANALYZE_SCHOOL_ENTITY_DATA = "SET_ANALYZE_SCHOOL_ENTITY_DATA"
export const SET_ANALYZE_SEARCH_VALUE = 'SET_ANALYZE_SEARCH_VALUE'
export const SET_ANALYZE_SCHOOL_SEARCH_VALUE = "SET_ANALYZE_SCHOOL_SEARCH_VALUE"
export const SET_ANALYZE_SEARCH_CANDIDATES = 'SET_ANALYZE_SEARCH_CANDIDATES'

export const SET_COMPARE_ENTITY = 'SET_COMPARE_ENTITY'
export const ADD_COMPARE_ENTITY = 'ADD_COMPARE_ENTITY'
export const SET_COMPARE_ENTITIES = 'SET_COMPARE_ENTITIES'
export const REMOVE_COMPARE_ENTITY = 'REMOVE_COMPARE_ENTITY'
export const CLEAR_COMPARE_ENTITIES = 'CLEAR_COMPARE_ENTITIES'
export const SET_COMPARE_SEARCH_VALUE = 'SET_COMPARE_SEARCH_VALUE'
export const SET_COMPARE_SEARCH_CANDIDATES = 'SET_COMPARE_SEARCH_CANDIDATES'
export const SET_ACTIVE_PORTFOLIO = 'SET_ACTIVE_PORTFOLIO'

export const SET_APP_CONTEXT = "SET_APP_CONTEXT"